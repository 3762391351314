@media screen and (min-width: 1200px) and (max-width: 1299px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
      .admin-dashboard-inner-box .admin-dash-price {
            font-size: 19px;
            line-height: 21px;
      }
}

/* phone and tablets */
@media screen and (max-width: 991px) {
      .upload-img-slider {
            height: 125px;
      }

      .add-game-row {
            margin-top: 10px;
      }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
      .bergur-btn-main {
            display: block;
      }

      .body-overflow {
            overflow: hidden;
      }

      .admin-sidebar-main {
            left: -500px;
            transition: all .5s;
            z-index: 9999;
            position: fixed;
            top: 0px;
            height: 100vh;
            border-radius: 0px;
      }

      .admin-sidebar-main.active {
            left: 0;
      }

      .close-icon {
            height: 23px;
            width: 23px;
            object-fit: contain;
            display: block;
      }

      .admin-sidebar-logo-main {
            display: flex;
            padding: 9px 0px 0px;
            justify-content: flex-end;
      }

      .bergur-btn-main .admin-bergur-button {
            min-width: max-content;
      }

      .dashboard-left-main {
            flex: 0 1;
            max-width: 0;
            width: 0;
      }

      .dashboard-right-main {
            margin-left: 0px;
      }

      .dashboard-containt-main {
            padding: 0px 0px 24px 0px;
            margin-left: 0px;
            margin-top: 110px;
      }

      .dashboard-content .admin-page-title {
            font-size: 21px;
            line-height: 23px;
      }

      .dashboard-content .admin-page-para {
            font-size: 14px;
            line-height: 23px;
      }


      .admin-login-left-main {
            display: none;
      }

      .admin-login-right-main {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .login-left-bg {
            display: none;
      }

      .admin-login-box {
            padding: 50px 15px 100px 15px;
      }

      .dashboard-graph {
            min-width: 100%;
      }

      .change-pass-main {
            max-width: 100%;
      }

}


/* phone */
@media (max-width: 767px) {

      .bergur-btn-main .admin-bergur-button {
            min-width: max-content;
      }

      .admin-header-main {
            padding: 10px 10px;
      }
      .checkbox-flex {
            margin-top: 0px;
        }
      .dashboard-containt-main {
            padding: 10px 0px 24px 0px;
            margin-left: 0px;
            margin-top: 80px;
      }

      .admin-dashboard-inner-box {
            max-width: 100%;
      }

      .dashboard-content .admin-page-title {
            font-size: 20px;
            line-height: 25px;
      }

      .admin-dashboard-inner-box .admin-dash-price {
            font-size: 17px;
            line-height: 23px;
      }

      .dash-icons {
            height: 100%;
            width: 100%;
            max-width: 120px;
      }


      .admin-login-left-main {
            display: none;
      }

      .admin-login-right-main {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
      }

      .admin-login-box {
            padding: 50px 15px 100px 15px;
      }

      .login-left-bg {
            display: none;
      }

      .add-user-input .form-control-textarea {
            min-width: 100%;
      }

      .user-btn-flex {
            margin-top: 10px;
      }

      .user-list-flex,
      .userlist-btn-flex {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 15px;
      }

      .user-list-inner-flex {
            width: 100%;
            justify-content: flex-end;
      }

      .export-btn-main .export-btn,
      .export-btn-main .export-btn:hover {
            margin-right: 0px;
            min-width: 150px;
      }

      .adduser-btn-main .adduser-btn,
      .adduser-btn-main .adduser-btn:hover {
            min-width: 150px;
      }

      .dashboard-content .user-list-page-title {
            margin-bottom: 20px;
      }

      .user-search-box {
            margin-bottom: 15px;
            margin-right: 0px;
      }

      .user-search-main {
            width: 100%;
      }

      .bergurmenu-icon {
            height: 20px;
            object-fit: contain;
            width: 20px;
            display: block;
      }

      .admin-header-icon-box {
            margin-right: 15px;
      }

      .admin-header-row .bergur-button {
            padding: 0px;
            min-width: 37px;
      }

      .dashboard-left-main {
            flex: 0 1;
            max-width: 0;
            width: 0;
      }

      .dashboard-right-main {
            margin-left: 0px;
      }

      .admin-sidebar-logo-main {
            display: flex;
            padding: 9px 0px 0px;
            justify-content: flex-end;
      }

      .close-icon {
            height: 23px;
            width: 23px;
            object-fit: contain;
            display: block;
      }

      .bergur-btn-main {
            display: block;
      }

      .admin-sidebar-main.active {
            left: 0;
      }

      .admin-sidebar-main {
            left: -500px;
            transition: all .5s;
            z-index: 9999;
            position: fixed;
            top: 0px;
            border-radius: 0px;
            height: 100vh;
            overflow: auto;
      }

      .password-input-box .MuiOutlinedInput-input {
            padding: 9.5px 12px;
      }

      .dashboard-graph {
            height: 200px;
            min-width: 100%;
      }

      .filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .filter-main {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            order: 1;
            margin-bottom: 14px;
      }

      .admin-header-search {
            display: none;
      }

      .page-table-main .table-td {
            font-size: 12px;
            line-height: 16px;
      }

      .modal .modal-inner {
            max-width: 335px;
      }

      .filter-checkbox-main .checkbox-lable {
            width: 100%;
            margin-bottom: 10px;
      }

      .admin-dashboard-inner-box .admin-dash-text {
            font-size: 14px;
            line-height: 17px;
      }

      .add-user-input {
            margin-bottom: 10px;
      }

      .admin-tabs-main .admin-tab {
            min-height: 30px;
      }

      .change-pass-main {
            max-width: 100%;
      }

      .body-overflow {
            overflow: hidden;
      }

      .dashboard-content .admin-page-title {
            font-size: 19px;
            line-height: 25px;
      }

      .dashboard-content .admin-page-para {
            font-size: 12px;
            line-height: 20px;
      }

      .admin-page-title-main {
            margin-bottom: 10px;
      }

      .cms-input-box {
            margin: 10px 0px 10px 0px;
      }

      .user-delete-dialong .MuiPaper-root {
            max-width: 300px;
      }

      .user-delete-dialong .userlist-dialog-text {
            font-size: 14px;
            line-height: 25px;
      }

      .file-upload-btn-main {
            max-width: 100%;
      }

      .file-upload-btn-main .file-upload-btn,
      .file-upload-btn-main .file-upload-btn:hover {
            min-width: 100%;
            height: 137px;
            width: 100%;
      }

      .profile-upload-flex {
            flex-direction: column;
      }

      .upload-input {
            margin-right: 0px;
      }

      .form-grp-upload-cover {
            width: 100%;
      }

      .noti-btns-main {
            margin-top: 0px;
      }
      .user-noti-btn-flex{
            margin-top: 20px;
      }
}